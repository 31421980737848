import './Error.css'

export function ErrorPage() {
    return (
       <div className="containerError">
<h1 class="error-code">404</h1>
<p class="error-message">Оопс! Страницата, която търсехте не може да бъде намерена.</p>

</div>
    );
  }